import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import img01_450 from "../assets/carousel/roofer_450.jpg"
import img01_768 from "../assets/carousel/roofer_768.jpg"
import img01_1920 from "../assets/carousel/roofer_1920.jpg"
import img01_3840 from "../assets/carousel/roofer_3840.jpg"

import img02_450 from "../assets/carousel/tools02_450.jpg"
import img02_768 from "../assets/carousel/tools02_768.jpg"
import img02_1920 from "../assets/carousel/tools02_1920.jpg"
import img02_3840 from "../assets/carousel/tools02_3840.jpg"

import img03_450 from "../assets/carousel/stars_450.jpg"
import img03_768 from "../assets/carousel/stars_768.jpg"
import img03_1920 from "../assets/carousel/stars_1920.jpg"
import img03_3840 from "../assets/carousel/stars_3840.jpg"

export default class carouselIndex extends Component {
  render() {
    const settings = {
      className: "slider",
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
      fade: true,
      infinite: true,
      lazyLoad: true,
      pauseOnHover: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 1000,
    }
    return (
      <div>
        <Slider {...settings}>
          <div className="carouselWrapper">
            <img
              className="carouselImage"
              src={img01_768}
              srcSet={`${img01_450} 450w, ${img01_768} 768w, ${img01_1920} 1925w, ${img01_3840} 3840w`}
              alt=""
            />
          </div>
          <div className="carouselWrapper">
            <img
              className="carouselImage"
              src={img02_768}
              srcSet={`${img02_450} 450w, ${img02_768} 768w, ${img02_1920} 1925w, ${img02_3840} 3840w`}
              alt=""
            />
          </div>
          <div className="carouselWrapper">
            <img
              className="carouselImage"
              src={img03_768}
              srcSet={`${img03_450} 450w, ${img03_768} 768w, ${img03_1920} 1925w, ${img03_3840} 3840w`}
              alt=""
            />
          </div>
        </Slider>
      </div>
    )
  }
}
