import React from "react"
import { Container, Row, Col, Table, Image } from "react-bootstrap"
import Header from "../components/header"
import Footer from "../components/footer"
import Carousel from "../components/carouselDachcheck"
import ContactBox from "../components/boxContact"
import Fade from "react-reveal/Fade"
import "../styles/dachcheck.css"

import imgDachCheck from "../assets/dachcheck.jpg"
import imgDachCheckPlus from "../assets/dachcheckplus.jpg"

export default () => (
  <div>
    <Header />
    <Carousel />
    <div className="contentbox white">
      <Container className="text-center">
        <h1>Dach-Check</h1>
        <hr />
        <Row className="show-grid text-left">
          <Col>
            <h5>Ihre Vorteile im Überblick:</h5>
          </Col>
        </Row>
        <Row className="show-grid text-left">
          <Col sm={12} md={true}>
            <ul class="dachcheck-list">
              <li>umfassender Versicherungsschutz</li>
              <li>Werterhalt der Immobilie</li>
              <li>volle Kostenkontrolle</li>
              <li>genaue Information über Beschaffenheit des Daches</li>
            </ul>
          </Col>
          <Col sm={12} md={true}>
            <ul class="dachcheck-list">
              <li>Vorbeugung möglicher Schadensquellen</li>
              <li>keine Vertragsverpflichtung</li>
              <li>Garantierverlängerung auf 10 Jahre möglich!</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="arrow-up gray" />
    <div className="contentbox gray">
      <Container className="text-center">
        <h4>
          Das Regelwerk des deutschen Dachdeckerhandwerk gibt Ihnen folgende
          Empfehlungen:
        </h4>
        <hr />
        <Table striped bordered hover className="text-left">
          <tbody className="dachcheck-table">
            <tr>
              <td valign="top">
                <strong>Alter des Daches:</strong>
              </td>
              <td valign="top">
                <strong>Bereich des Daches:</strong>
              </td>
              <td valign="top">
                <strong>Wartungsintervalle</strong>
              </td>
            </tr>
            <tr>
              <td valign="top">Bis 5 Jahre</td>
              <td valign="top">Dachdeckung</td>
              <td valign="top">Keine</td>
            </tr>
            <tr>
              <td valign="top">Ab dem 6. Jahr</td>
              <td valign="top">Dachdeckung + Stichproben Befestigung</td>
              <td valign="top">Alle 3 Jahre</td>
            </tr>
            <tr>
              <td valign="top">Ab dem 15. Jahr</td>
              <td valign="top">Deckung &amp; Stichprobe Befestigung Lattung</td>
              <td valign="top">Alle 2 Jahre</td>
            </tr>
            <tr>
              <td valign="top">Nach einen Sturm</td>
              <td valign="top">
                Deckung &amp; Stichprobe Befestigung Lattung, Auswirkung auf die
                Tragkonstruktion
              </td>
              <td valign="top">Schnellstmöglich</td>
            </tr>
          </tbody>
        </Table>
        <p align="center">
          <strong>
            {" "}
            Objektspezifische Besonderheiten können die Zeiträume der
            Wartungsintervalle verkürzen!
            <br />
          </strong>
        </p>
        <hr />
        <h6 align="center">Quelle: Deutsches Dachdeckerhandwerk Regelwerk</h6>
      </Container>
    </div>
    <div className="arrow-up white" />
    <div className="contentbox white">
      <Container className="text-center">
        <h4>Sie haben die Wahl zwischen unseren Programmen:</h4>
        <hr />
        <Row className="show-grid text-left">
          <Col sm={12} md={true} className="d-flex align-items-center">
            <Fade left>
              <Image
                src={imgDachCheck}
                alt="DachCheck"
                className="dachcheck-img"
              />
            </Fade>
          </Col>
          <Col sm={12} md={true}>
            <Fade right>
              <div>
                <ul class="dachcheck-list">
                  <li>Begehung Dachfläche</li>
                  <li>
                    Überprüfung jeglicher Einbauteile (z.B.: Eindeckrahmen
                    Dachfenster)
                  </li>
                  <li>Überprüfung jeglicher Entwässerungen</li>
                  <li>
                    Sichtkontrolle von:
                    <li>An- &amp; Abschlüsse</li>
                    <li>Lötnähte</li>
                    <li>Wartungsfugen</li>
                    <li>Verschraubungen</li>
                    <li>Bekleidungen (Gaube, Schornstein, Dachränder usw.)</li>
                    <li>
                      Dachdurchdringungen (Lüfter, Antennen, Dachfenster usw.)
                    </li>
                  </li>
                  <li>Inspektion der Sturmsicherungen</li>
                  <li>Prüfung Gesamteindruck, Verschleiß und Abnutzungsgrad</li>
                  <li>Ausarbeitung und Auswertung Dach-Check Prüfprotokoll</li>
                  <li>
                    Auflistung erforderlichen Maßnahmen, einschl.
                    Kostenvoranschlägen
                  </li>
                </ul>
              </div>
            </Fade>
          </Col>
        </Row>
        <hr />
        <Row className="show-grid text-left">
          <Col sm={12} md={true} className="d-flex align-items-center">
            <Fade left>
              <Image
                src={imgDachCheckPlus}
                alt="DachCheck Plus+"
                className="dachcheck-img"
              />
            </Fade>
          </Col>
          <Col sm={12} md={true}>
            <Fade right>
              <div>
                <ul class="dachcheck-list">
                  <li>Alle Leistungen des Dach-Check</li>
                  <li>Säuberung Einbauteile</li>
                  <li>Säuberungen Dachentwässerungen</li>
                  <li>Austausch defekter Deckwerkstoffe</li>
                  <li>Austausch Wartungsfugen</li>
                  <li>Behebung kleinerer Undichtigkeiten</li>
                </ul>
              </div>
            </Fade>
          </Col>
        </Row>
        <hr />
      </Container>
    </div>
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
